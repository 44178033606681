<template>
  <div class="p-5 font-size-30">
    <div class="font-weight-bold mb-3 text-danger text-center">Página no encontrada</div>
    <div class="small text-danger text-justify">La página no está disponible, debido a que ha sido borrada, ya no existe o es inaccesible.</div>
    <div class="font-weight-bold font-size-70 mb-1 text-center text-danger">Error 404</div>
    <router-link to="/" class="btn btn-primary btn-block">
      <b-icon icon="house-fill"/>
      Regresar a Inicio
    </router-link>
  </div>
</template>
<script>
export default {
  name: 'CuiError404',
}
</script>
