<template>
  <div>
    <air-error-404 />
  </div>
</template>
<script>
import AirError404 from '@/@airui/system/Errors/404'
export default {
  components: {
    AirError404,
  },
}
</script>
